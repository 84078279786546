import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Col, Container, Row } from 'react-bootstrap';
import sanitizeHtml from 'sanitize-html';

import Link from 'ui-kit/link/link';
import { LinkVariant } from 'ui-kit/link/link.props';

import ErrorScreenLayout from 'components/layouts/error-screen/error-screen.layout';

import { getPhoneNumber } from 'util/globalVariables';

import useResetToken, { flowTypes } from 'hooks/useResetToken';

import './link-expired.style.scss';

const LinkExpired = ({ location, data }: { location: Location; data: GatsbyTypes.LinkExpiredDataQuery }) => {
    const { t } = useTranslation();

    const params = new URLSearchParams(location.search);
    const flowParameter = params.get('flow');

    useResetToken(flowParameter as flowTypes);

    const getButtonLabel = (flowParameter: string | null) => {
        switch (flowParameter) {
            case 'auto-refill':
                return t('pages.linkExpired.buttonLabelAutoRefill');
            case 'easy-refill':
                return t('pages.linkExpired.buttonLabelEasyRefill');
            case 'caregiver-access':
                return t('pages.linkExpired.buttonLabelCaregiverAccess');
            case 'join-membership-plan':
                return t('pages.linkExpired.buttonLabelJoinMembershipPlan');
            case 'email-verification':
                return t('pages.linkExpired.buttonLabel');
            case 'unblock-account':
                return t('Go to home page');
            default:
                return t('pages.linkExpired.buttonLabel');
        }
    };

    const getButtonTo = (flowParameter: string | null) => {
        switch (flowParameter) {
            case 'join-membership-plan':
                return '/';
            default:
                return '/sign-in';
        }
    };

    const getVariant = (flowParameter: string | null): LinkVariant => {
        switch (flowParameter) {
            case 'caregiver-access':
                return 'underline-blue-bold';
            case 'email-verification':
            case 'join-membership-plan':
                return 'underline-blue';
            case 'unblock-account':
                return 'cta-button';

            default:
                return 'underline-blue';
        }
    };

    return (
        <ErrorScreenLayout title={t('pages.linkExpired.title')}>
            <Container className="link-expired">
                <Row>
                    <Col xs={12} lg={{ span: 8, offset: 2 }}>
                        {(flowParameter && flowParameter === 'easy-refill') || flowParameter === 'auto-refill' ? (
                            <>
                                <p>{t('pages.linkExpired.autoRefillSubtitle1')}</p>

                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: sanitizeHtml(
                                            t('pages.linkExpired.autoRefillSubtitle2', {
                                                phoneNumber: getPhoneNumber({ isEnd: true })
                                            })
                                        )
                                    }}
                                ></p>
                            </>
                        ) : flowParameter === 'caregiver-access' ? (
                            <>
                                <p>{t('pages.linkExpired.caregiverAccessSubtitle1')}</p>

                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: sanitizeHtml(
                                            t('pages.linkExpired.caregiverAccessSubtitle2', {
                                                phoneNumber: getPhoneNumber({ isEnd: true })
                                            })
                                        )
                                    }}
                                ></p>
                            </>
                        ) : flowParameter === 'join-membership-plan' ? (
                            <>
                                <p className="join-membership-plan-subtitle1">
                                    {t('pages.linkExpired.joinMembershipPlanSubtitle1')}
                                </p>
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: sanitizeHtml(
                                            t('pages.linkExpired.joinMembershipPlanSubtitle2', {
                                                phoneNumber: getPhoneNumber({ isEnd: true })
                                            })
                                        )
                                    }}
                                ></p>
                            </>
                        ) : flowParameter === 'email-verification' ? (
                            <>
                                <p className="title">{t('pages.linkExpired.emailVerificationText')}</p>

                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: sanitizeHtml(
                                            t('pages.linkExpired.emailVerificationBoldPhone', {
                                                phoneNumber: getPhoneNumber({ isEnd: true })
                                            })
                                        )
                                    }}
                                ></p>
                            </>
                        ) : flowParameter === 'unblock-account' ? (
                            <>
                                <p className="title">{t('pages.linkExpired.unblockAccountSubtitle1')}</p>

                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: sanitizeHtml(
                                            t('pages.linkExpired.unblockAccountSubtitle2', {
                                                phoneNumber: getPhoneNumber({ isEnd: true })
                                            })
                                        )
                                    }}
                                ></p>
                            </>
                        ) : (
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: sanitizeHtml(
                                        t('pages.linkExpired.subtitle', {
                                            phoneNumber: getPhoneNumber({ isEnd: true })
                                        })
                                    )
                                }}
                            />
                        )}

                        <Link
                            to={getButtonTo(flowParameter)}
                            label={getButtonLabel(flowParameter)}
                            variant={getVariant(flowParameter)}
                            className={flowParameter ? `${flowParameter}-link` : undefined}
                            dataGALocation="LinkExpired-Signin"
                            ariaLabel="LinkExpired-Signin"
                        />
                    </Col>
                </Row>
            </Container>
        </ErrorScreenLayout>
    );
};

export default LinkExpired;

export const query = graphql`
    query LinkExpiredData($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
